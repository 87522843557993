import {
  Flex,
  Box,
  FormControl,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast,
  Checkbox
} from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import axios from './axios';
import Header from './Header';

export default function Password() {
  const [old_password, setOldPassword] = useState('')
  const [new_password, setNewPassword] = useState('')
  const [confirm_new_password, setConfirmNewPassword] = useState('');
  const [token, setToken] = useState('')
  const [img, setImg] = useState('')
  const [loading, setLoading] = useState(false)

  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef()
  const finalRef = useRef();

  const matchers = useMemo(() => {
    return [
      { error: 'Password length must be more than 8 characters', pass: new RegExp('.{8,}').test(new_password) },
      { error: 'Password must contain at least one digit', pass: new RegExp('[\\d]{1}').test(new_password) },
      { error: 'Password must contain at least one special character', pass: new RegExp('[!@#$%^&*(),.?":{}|<>`~/-_/\\+=]').test(new_password) },
      { error: 'Password must contain at least one uppercase letter', pass: new RegExp('[A-Z]').test(new_password) },
      { error: 'Password must contain at least one lowercase letter', pass: new RegExp('[a-z]').test(new_password) },
      { error: 'Confirm new password', pass: confirm_new_password === new_password },
      { error: 'New and old password must not match', pass: new_password !== old_password },
    ]
  }, [old_password, new_password, confirm_new_password]);

  useEffect(() => {
    if (!localStorage.getItem('username')) {
      window.location.href = '/login';
    }
  }, [])

  const handleSetup = async () => {
    setLoading(true)
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/setup`, {
        username: localStorage.getItem('username'), old_password, new_password, token
      });
      if (data.responseCode === "00") {
        localStorage.setItem('token', data.data.token)
        window.location.href = '/dashboard';
      } else if (data.responseCode === "setup") {
        setImg(data.data)
        setOldPassword('')
        setNewPassword('')
        setConfirmNewPassword('')
        onOpen()
      }
    } catch (error) {
      toast({
        title: error.response?.data?.responseMessage ||
          error.response?.statusText ||
          error.message ||
          'Seems like something went wrong with your request. Please try again.',
        position: 'top-right',
        isClosable: true,
        status: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Header />
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack spacing={8} mx={'auto'} py={12} px={6}>
          <Stack>
            <Heading fontSize={'2xl'}>Change Password</Heading>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}
            minW={'50vw'}>
            <Stack spacing={4}>
              <FormControl id="password">
                <Input type="password" placeholder='Old Password' value={old_password} onChange={(e) => setOldPassword(e.target.value)} />
              </FormControl>
              <FormControl mt={4}>
                <Input type="password" placeholder='New Password' value={new_password} onChange={(e) => setNewPassword(e.target.value)} />
              </FormControl>
              <FormControl mt={4}>
                <Input type="password" placeholder='Confirm New Password' value={confirm_new_password} onChange={(e) => setConfirmNewPassword(e.target.value)} />
              </FormControl>
              {matchers.map((match, index) => (<span key={index}> <Checkbox size='sm' disabled isChecked={match.pass}> {match.error} </Checkbox></span>))}

              <Stack spacing={10}>
                <Button
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  isLoading={loading}
                  disabled={loading || !matchers.every((match) => !!match.pass)}
                  onClick={handleSetup}>
                  Save
                </Button>
              </Stack>
            </Stack>
          </Box>
          <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={() => {
              setToken('')
              onClose();
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Setup Two Factor Authentication</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Stack align={'center'}>
                  <img src={img} alt='' />
                </Stack>
                <span>Use your two factor authentication app to scan the QR code above. After scanning the QR Code, enter the 6 digit code displayed below.</span>
                <FormControl mt={4}>
                  <Input placeholder='6 Digit Code' value={token} onChange={(e) => setToken(e.target.value)} />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    setToken('')
                    onClose();
                  }}
                  mr={3}>Cancel</Button>
                <Button
                  colorScheme='blue'
                  onClick={handleSetup}
                  isLoading={loading}
                  disabled={loading}>
                  Save
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Stack>
      </Flex>
    </>
  );
}