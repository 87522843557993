import { Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import Dashboard from "./Dashboard";
import Login from "./Login";
import Password from "./Password";
import Runner from "./Runner";

function RequireAuth({ children, redirectTo }) {
  let isAuthenticated = !!localStorage.getItem('token');
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/password" element={<Password />} />
      <Route path="/dashboard" element={
        <RequireAuth redirectTo="/login">
          <Dashboard />
        </RequireAuth>
      } />
      <Route path="/" element={
        <RequireAuth redirectTo="/login">
          <Dashboard />
        </RequireAuth>
      } />
      <Route path="/runner" element={
        <RequireAuth redirectTo="/login">
          <Runner />
        </RequireAuth>
      } />
    </Routes>
  );
}

export default App;
