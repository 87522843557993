import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import axios from './axios';

export default function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [token, setToken] = useState('')
  const [showToken, setShowToken] = useState(false)
  const [loading, setLoading] = useState(false)

  const toast = useToast()

  useEffect(() => {
    localStorage.removeItem('token')
  }, [])

  const handleLogin = async () => {
    setLoading(true)
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/login`, {
        username, password, token
      });
      localStorage.setItem('username', username)
      if (data.responseCode === "setup") {
        window.location.href = '/password';
      } else if (data.responseCode === "token") {
        setShowToken(true)
      } else if (data.responseCode === "00") {
        localStorage.setItem('token', data.data.token)
        window.location.href = '/dashboard';
      }
    } catch (error) {
      toast({
        title: error.response?.data?.responseMessage ||
          error.response?.statusText ||
          error.message ||
          'Seems like something went wrong with your request. Please try again.',
        position: 'top-right',
        isClosable: true,
        status: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} py={12} px={6}>
        <Stack>
          <Heading fontSize={'2xl'}>Welcome!</Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
          minW={'50vw'}>
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Username</FormLabel>
              <Input type="email" value={username} onChange={(e) => setUsername(e.target.value)} />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
            {!!showToken && (
              <FormControl id="token">
                <FormLabel>Token</FormLabel>
                <Input type="token" value={token} onChange={(e) => setToken(e.target.value)} />
              </FormControl>
            )}

            <Stack spacing={10}>
              <Button
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                isLoading={loading}
                disabled={loading}
                onClick={handleLogin}>
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}