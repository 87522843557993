import {
  Box,
  Flex,
  Avatar,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
} from '@chakra-ui/react';

export default function Header() {
  const username = localStorage.getItem('username')
  if (!username) return null
  return (
    <Box bg={'gray.100'} px={4}>
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>

        <HStack spacing={8} alignItems={'center'}>

        </HStack>
        <Flex alignItems={'center'}>
          <Text color='gray.500' mr={4}>
            {username}
          </Text>
          <Menu>
            <MenuButton
              as={Button}
              rounded={'full'}
              variant={'link'}
              cursor={'pointer'}
              minW={0}>
              <Avatar
                size={'sm'}
              />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => {
                window.location.href = '/password'
              }}>Change Password</MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => {
                localStorage.removeItem('username')
                localStorage.removeItem('token')
                window.location.href = '/login'
              }}>Logout</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Box>

  );
}