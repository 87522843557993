import {
  Box,
  FormControl,
  Input,
  Button,
  Heading,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Container,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  InputGroup,
  InputLeftAddon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Spacer,
  Switch,
  FormLabel,
} from '@chakra-ui/react';

import { EditIcon, DownloadIcon, ArrowUpIcon } from '@chakra-ui/icons'
import React, { useEffect, useRef, useState } from 'react';
import axios from './axios';
import Header from './Header';


export default function Dashboard() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [config, setConfig] = useState({});
  const [configs, setConfigs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [environment, setEnvironment] = useState(localStorage.getItem('environment') || 'production')

  const toast = useToast()

  const getConfigurations = async (environment) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/configurations?environment=${environment}`)
      setConfigs(data.data.reduce((acc, item) => {
        if (acc[item.service]) acc[item.service].push(item)
        else acc[item.service] = [item]
        return acc;
      }, {}))
    } catch (error) {
      toast({
        title: error.response?.data?.responseMessage ||
          error.response?.statusText ||
          error.message ||
          'Seems like something went wrong with your request. Please try again.',
        position: 'top-right',
        isClosable: true,
        status: 'error'
      })
    }
  }

  useEffect(() => {
    getConfigurations(environment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateConfiguration = async (id, value) => {
    setLoading(true)
    try {
      await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/admin/configurations/${id}`, {
        value
      });
      getConfigurations(environment)
    } catch (error) {
      toast({
        title: error.response?.data?.responseMessage ||
          error.response?.statusText ||
          error.message ||
          'Seems like something went wrong with your request. Please try again.',
        position: 'top-right',
        isClosable: true,
        status: 'error'
      })
    } finally {
      setLoading(false)
      onClose()
    }
  }

  const initialRef = useRef()
  const finalRef = useRef();
  const fileRef = useRef();

  return (
    <>
      <Header />
      <Container maxW={'5xl'} py={12}>
        <Flex align={'center'}>
          <Heading fontSize={'2xl'} py={5}>System Configurations</Heading>
          <Spacer />
          <>
            <Switch
              size='lg'
              isChecked={environment === 'production'}
              onChange={() => {
                const newenvironment = environment === 'production' ? 'development' : 'production';
                setEnvironment(newenvironment);
                localStorage.setItem('environment', newenvironment)
                setConfigs([])
                getConfigurations(newenvironment);
              }} />
            <FormLabel ml={2}>{environment}</FormLabel>
          </>
        </Flex>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          {Object.keys(configs).map((k) => (
            <Accordion allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex='1' textAlign='left'>
                      {k.toUpperCase()}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Table variant='simple'>
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Description</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {configs[k].map((d) => (
                        <Tr key={d.id}>
                          <Td>{d.name}</Td>
                          <Td>{d.description}</Td>
                          <Td>
                            <IconButton mr={'2'} size='sm' icon={<EditIcon />} onClick={() => {
                              setConfig(d)
                              onOpen()
                            }} />
                            {!!d.downloadable && (<IconButton mr={'2'} size='sm' icon={<DownloadIcon />} onClick={() => {
                              window.location.href = `${process.env.REACT_APP_API_BASE_URL}/admin/configurations/${d.id}/download?token=${localStorage.getItem('token')}`
                            }} />)}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ))}
        </Box>
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update {config?.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <Input placeholder='Key' value={config?.key} disabled />
              </FormControl>
              {!config?.downloadable && (
                <FormControl mt={4}>
                  <Input required placeholder='Value' ref={initialRef} defaultValue={config?.value} disabled={!!config?.read_only} />
                </FormControl>
              )}
              {!!config?.downloadable && (
                <FormControl mt={4}>
                  <InputGroup>
                    <InputLeftAddon
                      pointerEvents='none'
                      children={<ArrowUpIcon color='gray.300' />}
                    />
                    <input
                      type='file'
                      ref={fileRef}
                      style={{ display: 'none' }}
                    />
                    <Input
                      placeholder={"Your file ..."}
                      onClick={() => fileRef.current.click()}
                      value={fileRef.current?.value}
                      readOnly
                    />
                  </InputGroup>
                </FormControl>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme='blue'
                mr={3}
                isLoading={loading}
                disabled={loading}
                onClick={() => {
                  updateConfiguration(config.id, initialRef.current.value)
                }}>
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Container>
    </>
  );
}