import axios from 'axios';
const prompt_login = 'Please Login';

axios.interceptors.request.use(
  async request => {
    if (!request.headers.Authorization) {
      request.headers.Authorization = `Bearer ${localStorage.getItem(
        'token',
      )}`;
    }
    return request;
  },
  error => {
    return Promise.reject(error);
  },
);

// Intercept all responses
axios.interceptors.response.use(
  async response => {
    return response;
  },
  error => {
    if ([403, 401].includes(error?.response?.status)) {
      error.message = prompt_login;
      window.location.href = '/login'
    } else if (error?.response?.data) {
      error.message = error.response.data.message;
    }
    return Promise.reject(error);
  },
);

export default axios;
