import {
  Box,
  FormControl,
  Input,
  Button,
  Heading,
  useColorModeValue,
  Container,
  useToast,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuGroup,
  Stack,
  FormLabel,
  Code,
} from '@chakra-ui/react';

import { ChevronDownIcon } from '@chakra-ui/icons'
import React, { useEffect, useState } from 'react';
import axios from './axios';
import Header from './Header';


export default function Runner() {
  const [config, setConfig] = useState({});
  const [examples, setExamples] = useState([]);
  const [paramsFormData, setParamsFormData] = useState([])
  const [bodyFormData, setBodyFormData] = useState([])
  const [example, setExample] = useState(null);
  const [response, setResponse] = useState(null)

  const [params, setParams] = useState({});
  const [body, setBody] = useState({});

  const [loading, setLoading] = useState(false)

  const toast = useToast()

  const getExamples = async () => {
    try {
      const { data: configurations } = await axios.get(process.env.REACT_APP_API_BASE_URL + '/admin/configurations')
      setConfig(configurations.data.reduce((acc, item) => {
        acc[item.key] = item.value
        return acc;
      }, {}));

      const { data: examples } = await axios.get(process.env.REACT_APP_API_BASE_URL + '/admin/examples');
      const grouped = examples.data.reduce((acc, curr) => {
        if (acc[curr.service]) {
          acc[curr.service].push(curr)
        } else {
          acc[curr.service] = [curr]
        }
        return acc;
      }, {});
      setExamples(Object.keys(grouped).map(item => ({
        service: item,
        examples: grouped[item]
      })))
    } catch (error) {
      toast({
        title: error.response?.data?.responseMessage ||
          error.response?.statusText ||
          error.message ||
          'Seems like something went wrong with your request. Please try again.',
        position: 'top-right',
        isClosable: true,
        status: 'error'
      })
    }
  }

  const makeRequest = async () => {
    try {
      setLoading(true)
      let url = example.path;
      for (const key in params) {
        url = url.replace(new RegExp(`:${key}`, 'g'), params[key])
      }
      const response = await axios.request({
        method: example.method,
        baseURL: config?.base_url,
        url,
        data: Array.isArray(example.body) ? [body] : body,
        headers: {
          Authorization: config?.auth
        }
      });
      setResponse(JSON.stringify(response.data, undefined, 4))
    } catch (error) {
      toast({
        title: error.response?.data?.responseMessage ||
          error.response?.statusText ||
          error.message ||
          'Seems like something went wrong with your request. Please try again.',
        position: 'top-right',
        isClosable: true,
        status: 'error'
      })
      setResponse(JSON.stringify(error.response?.data, undefined, 4))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getExamples()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateForm = (example) => {
    const params_form = example.path.match(/:[^\\/]+/g)?.map(param => param.substr(1));
    const body_form = Object.keys((Array.isArray(example.body) ? example.body[0] : example.body) || {})
    setParamsFormData(params_form || [])
    setBodyFormData(body_form || []);
    setExample(example);
  }

  return (
    <>
      <Header />
      <Container maxW={'5xl'} py={12}>
        <Heading fontSize={'2xl'} py={5}>Request Runner</Heading>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Menu>
            <MenuButton
              px={4}
              py={2}
              transition='all 0.2s'
              borderRadius='md'
              borderWidth='1px'
            >
              {example ? `${example.service?.toUpperCase()} - ${example.title}` : 'Select Service'} <ChevronDownIcon />
            </MenuButton>
            <MenuList as="ul" maxHeight={"41vh"} overflowY={"scroll"}>
              {examples.map(e => (
                <MenuGroup
                  title={e.service?.toUpperCase()}>{e.examples.map(example =>
                    <MenuItem as="li"
                      value={example.title}
                      onClick={() => {
                        populateForm(example)
                        setResponse(null)
                      }}>{example.title}
                    </MenuItem>
                  )}<MenuDivider />
                </MenuGroup>
              ))}
            </MenuList>
          </Menu>
          <Stack spacing={4} paddingTop={"10"} align={'center'}>
            {paramsFormData.map(p => (
              <FormControl id={p} key={p}>
                <FormLabel>{p.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase())}</FormLabel>
                <Input
                  type="text"
                  defaultValue={''}
                  onChange={(e) => setParams({ ...params, [p]: e.target.value })}
                />
              </FormControl>
            ))}
            {bodyFormData.map(b => (
              <FormControl id={b} key={b}>
                <FormLabel>{b.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase())}</FormLabel>
                <Input type="text" defaultValue={''} onChange={(e) => setBody({ ...body, [b]: e.target.value })} />
              </FormControl>
            ))}
            <Button
              alignSelf={'start'}
              colorScheme='blue'
              mr={3}
              isLoading={loading}
              disabled={loading}
              onClick={(e) => makeRequest()}>
              Make Request
            </Button>
            <Code alignSelf={'start'}>
              {response}
            </Code>
          </Stack>
        </Box>
      </Container>
    </>
  );
}